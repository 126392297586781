import React from 'react'
import { BrowserRouter as Router, useLocation, Switch, Route } from 'react-router-dom'
import Lasherasval from './components/lasherasval'
import Maipuval from './components/maipuval'
import Finproceso from './components/finproceso'
import Generalerror from './components/error'
import Notfound from './components/notfound'
import Confirmacion from './components/confirmacion'
import 'bootstrap/dist/css/bootstrap.min.css';




const App = () => {

  document.title = "Revitotal - Turnos";
  return (
    <div>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/lasheras" exact component={Lasherasval} />
            <Route path="/maipu" exact component={Maipuval} />
            <Route path="/finproceso" exact component={Finproceso} />
            <Route path="/confirmado" exact component={Confirmacion} />
            <Route path="/error" exact component={Generalerror} />
            <Route component={Notfound} />
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App;