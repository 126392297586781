import React from 'react'
import Header from './header'
import { connect } from 'react-redux'
import { setError } from '../redux/actions/turnero'



const Error = (props) => {

    const { error } = props


    return (
        <div style={{ position: "relative" }}>

            <div style={{ position: "absolute", top: "70px", background: "black", opacity: "0.65", minHeight: "100vh", height: "100%", width: "100%" }}></div>



            <Header></Header>

            <div style={{ textAlign: "center", background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "60px", lineHeight: "45px" }} className="card responsive-card">

                <p>{error.message}</p>




            </div>





        </div >
    )
}

const mapStateToProps = state => ({
    error: state.turnero.error
})

const mapDispatchToProps = {
    setError: setError

}

export default connect(mapStateToProps, mapDispatchToProps)(Error)
