import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setPrecio, desactivarCargando, setTipoVehiculo, setTipoVehiculoEsp, setTurnosObtenidos, setDiasObtenidos, setDiaSeleccionado, setHoraSeleccionada, setErrorOut, setTipoVehiculoPrecio } from '../redux/actions/turnero'
import Elecciondia from './elecciondia'
import Eleccionturno from './eleccionturno'
import Diaseleccionado from './diaseleccionado'
import Horaseleccionada from './horaseleccionada'
import Ingresoemail from './ingresoemail'

const Vehiculoseleccionado = (props) => {

    const { setPrecio, planta, temp, final, setErrorOut, setTipoVehiculo, setTipoVehiculoEsp, desactivarCargando, setTurnosObtenidos, setDiasObtenidos, setDiaSeleccionado, setHoraSeleccionada } = props

    useEffect(() => {

        const dias = [];
        temp.turnosObtenidos.map((turno, i) => {
            if (dias.find((reg) => reg === turno.fecha) === undefined) {
                dias.push(turno.fecha);
            }
        });
        setDiasObtenidos(dias);


    }, [])

    return (
        <div>



            <div style={{ background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "8px", lineHeight: "45px" }} className="card responsive-card">

                {planta === "lasheras" ?
                    <h1>Planta Las Heras</h1>
                    :
                    <h1>Planta Maipu</h1>
                }

                <p>Tenga en cuenta que su turno estará confirmado una vez realizado el pago.<br />La plataforma lo redireccionará al sitio para efectuarlo.</p>

                <span style={{ display: "inline", fontSize: "17px" }}>
                    <b>Tipo de vehiculo:</b>
                    {' ' + temp.tipoVehiculo + '    '}
                    <br />
                    <b>Precio:</b>
                    {'  $' + temp.precio + '.-'}
                </span>
            </div>




            {temp.diaSeleccionado ?
                <Diaseleccionado></Diaseleccionado> : <Elecciondia></Elecciondia>
            }

            {(temp.diaSeleccionado && !temp.horaSeleccionada) && <Eleccionturno></Eleccionturno>}

            {temp.horaSeleccionada && <>

                <Horaseleccionada></Horaseleccionada>
                <Ingresoemail></Ingresoemail>

            </>}




        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp,
    final: state.turnero.final,
    mostrar: state.turnero.mostrar,
    planta: state.turnero.planta
})

const mapDispatchToProps = {
    setTipoVehiculo: setTipoVehiculo,
    setTipoVehiculoEsp: setTipoVehiculoEsp,
    desactivarCargando: desactivarCargando,
    setTurnosObtenidos: setTurnosObtenidos,
    setDiasObtenidos: setDiasObtenidos,
    setDiaSeleccionado: setDiaSeleccionado,
    setHoraSeleccionada: setHoraSeleccionada,
    setErrorOut: setErrorOut,
    setPrecio: setPrecio
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehiculoseleccionado)
