import React from 'react'

const Header = () => {
    return (
        <>
            <header style={{ background: "#74ACDF", height: "70px", width: "100%" }}>
                <div className="container">

                    <div className="brand" style={{ float: "left", fontFamily: "Nunito", lineHeight: "75px", color: "white", height: "70px", fontSize: "1.85rem" }}>
                        REVITOTAL
                    </div>
                    <div className="volver-sitio" style={{ float: "right", fontFamily: "Nunito", lineHeight: "70px", color: "white", height: "70px", fontSize: "1rem" }}>
                        <a style={{ textDecoration: "none", color: "white" }} href="https://revitotal.com.ar">SITIO WEB</a>
                    </div>


                </div>
            </header>
        </>
    )
}

export default Header;
