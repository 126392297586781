import React from 'react'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { setTipoVehiculo, setNroTurno, setPlantaMa } from '../redux/actions/turnero'
import Header from './header'
import Vehiculoseleccionado from './vehiculoseleccionado'
import '../styles/estilos.css'



const Maipu = (props) => {


    const { temp, setPlantaMa } = props;
    const [cargando, setCargando] = useState(false);


    useEffect(() => {
        setPlantaMa()
    }, [temp.turnosObtenidos])


    return (
        <div style={{ position: "relative" }}>

            <div style={{ position: "absolute", top: "70px", background: "black", opacity: "0.65", minHeight: "100vh", height: "100%", width: "100%" }}></div>



            <Header></Header>

            <div className="container p-4" style={{ textAlign: "center" }}>





                {temp.tipoVehiculo &&
                    <Vehiculoseleccionado></Vehiculoseleccionado>
                }

            </div>



        </div >
    )
}


const mapStateToProps = state => ({
    temp: state.turnero.temp,
    final: state.turnero.final,
    mostrar: state.turnero.mostrar
})

const mapDispatchToProps = {
    setTipoVehiculo: setTipoVehiculo,
    setNroTurno: setNroTurno,
    setPlantaMa: setPlantaMa
}

export default connect(mapStateToProps, mapDispatchToProps)(Maipu)
